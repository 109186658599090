<!-- 用户管理 > 用户编辑(组件) > 劳动合同(组件) -->
<template>
    <div class="projectSubitem" v-loading="loading">
        <UploadList v-model="uploadData" fileCode="agreement_fj" :disabled="true" :ywDataId="manageId"></UploadList>

        <!-- <div class="fullScreenOperation">
            <el-button

                    type="success"
                    :loading="saveloading"
                    @click="save"
            >
                保存
            </el-button>
            <el-button type="info" plain @click="re">返回</el-button>
        </div> -->
    </div>
</template>
<script>

export default {
    components: {
        UploadList: () => import("@/views/user/agreement/AddUploadList.vue"),

    },
    inject: ['re'],
    data() {
        return {
            loading: false,
            saveloading: false,
            uploadData: [],
            filepath:process.env.VUE_APP_FILEPATH,
            tableData:[],
        };
    },
    props: {
        manageId: {
        },
    },
    watch:{
        manageId:{
            immediate:true,
            handler: function (a){
                if(a){
                    this.getData();
                }
            }
        }
    },
    methods: {
        upload(r){
            r.some(res=>{
                this.tableData.push(res.combination({
                    fileCode:this.fileCode,
                }));
            });
            // console.log(this.tableData)

            this.$emit('change', this.tableData);
        },
        del(scope){
            if(scope.row.id){
                this.$api.user.deleteUserAttachment(scope.row.id).then(res => {
                    this.tableData.splice(scope.$index,1);
                    this.$emit('change', this.tableData);
                }).catch(err => {});
            }else {
                this.tableData.splice(scope.$index,1);
                this.$emit('change', this.tableData);
            };
        },
        getData(){
            this.$api.user.getLaborContract(this.manageId).then(res => {
                this.uploadData = res.data;
            }).catch(err => {
                console.log(err);
            });
        },
        save(){
            if(this.uploadData.some(r => {
                return r.loading
            })){
                this.$message.warning("文件上传中，请稍后再试！");
                return '';
            }
            this.uploadData[0].staffId = this.manageId;
            this.saveloading = true;
            this.$api.user.addLaborContract(this.uploadData[0]).then(res => {//只能传入 一份劳动合同
                this.saveloading = false;
                this.$message.success("操作成功！");
                this.add_re();
            }).catch(err => {
                console.log(err);
                this.saveloading = false;
            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";

</style>
